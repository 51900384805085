/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import { useEffect } from 'react';
import Script from 'next/script'; //
import ProgressBar from '@/components/Layout/ProgressBar';
import { TransitioningProvider, useTransition } from '@/hooks/useTransition';
import GlobalContainer from '@/components/Layout/GlobalContainer';
import Banner from '@/components/Layout/Banner';
import ErrorPage from '@/components/Layout/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './styles.scss';
import getEnv from '@/utils/getEnv';
import { useAmp } from 'next/amp';
import { getCurrentHub } from '@sentry/nextjs';
import './global.css';

export default function App({ Component, pageProps }) {
  if (pageProps.newsPreview) {
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=yes, maximum-scale=5.0, minimum-scale=0.86" />
          <meta className="versionExt" content="2" />
        </Head>
        <Component {...pageProps} />
      </>
    );
  }

  const transaction = getCurrentHub().getScope()?.getTransaction();
  const appSpan = transaction && transaction.startChild({ op: 'app' });
  const { isTransitioning } = useTransition();

  const isAmp = useAmp();

  useEffect(() => {
    const { googletag } = window;
    googletag.cmd.push(() => {
      const REFRESH_KEY = 'refresh';
      const REFRESH_VALUE = 'true';
      const SECONDS_TO_WAIT_AFTER_VIEWABILITY = 20;

      googletag.companionAds().setRefreshUnfilledSlots(true);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().enableVideoAds();
      googletag.pubads().collapseEmptyDivs();

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 150,
        renderMarginPercent: 100,
        mobileScaling: 1.0,
      });

      googletag.pubads().addEventListener('impressionViewable', event => {
        const { slot } = event;
        if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
          setTimeout(() => {
            if (slot.getTargeting('refreshed_slot')) {
              slot.setTargeting('refreshed_slot', 'true');
            }
            googletag.pubads().refresh([slot]);

            // eslint-disable-next-line no-console
            if (getEnv('ENVIRONMENT') === 'development')
              console.log('REFRESH_AUTO_VIEWABLE', new Date(), slot.getTargeting('type-slot-ad')[0], slot.getSlotId().getId());
          }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
        }
      });

      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    }, []);
  }, []);

  useEffect(() => {
    if (getEnv('ENVIRONMENT') === 'production' && !isAmp) {
      window?.marfeel?.cmd?.push([
        'compass',
        function (compass) {
          compass.trackNewPage();
        },
      ]);
    }
  }, [isTransitioning]);

  return (
    <>
      <Head>
        {!isAmp && <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=yes" />}
        <meta className="versionExt" content="2" />
      </Head>
      <TransitioningProvider>
        <ProgressBar />
        <Script strategy="afterInteractive" async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `var googletag = googletag || {};
            googletag.cmd = googletag.cmd || [];
            `,
          }}
        />
        <Script
          dangerouslySetInnerHTML={{
            __html: `var _comscore = _comscore || [];
          _comscore.push({
          c1: "2", c2: "20572948",
          options: {
          enableFirstPartyCookie: true,
          bypassUserConsentRequirementFor1PCookie: true
          }
          });
          (function() {
          var s = document.createElement("script"), el =
          document.getElementsByTagName("script")[0]; s.async = true;
          s.src = "https://sb.scorecardresearch.com/cs/20572948/beacon.js";
          el.parentNode.insertBefore(s, el);
          })();`,
          }}
        />

        <noscript>
          <img src="https://sb.scorecardresearch.com/p?c1=2&amp;c2=20572948&amp;cv=cv=4.4.0&amp;cj=1" alt="" />
        </noscript>
        <ErrorBoundary
          fallback={
            <>
              <Head>
                <title>Canaltech | Ooops. Algo deu errado</title>
              </Head>
              <GlobalContainer whiteBg center>
                <Banner />
                <ErrorPage isGeneric statusCode="Ooops! Algo deu errado. " />
              </GlobalContainer>
            </>
          }>
          <Component {...pageProps} />
        </ErrorBoundary>
      </TransitioningProvider>
      {appSpan && appSpan.finish()}
    </>
  );
}
